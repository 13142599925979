import { FC } from "react";
import joinClassNames from "classnames";

import Coin56Icon from "icons/coin56.svg?react";
import Coin72Icon from "icons/coin72.svg?react";
import CoinSprite56Icon from "icons/coin-sprite56.svg?react";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  type?: "default" | "points";
}

const AnimatedCoin: FC<Props> = ({ className, type = "default" }) => {
  const isDefault = type === "default";
  const FaceIcon = isDefault ? Coin56Icon : Coin72Icon;
  const BackIcon = isDefault ? CoinSprite56Icon : Coin72Icon;

  return (
    <div
      data-type={type}
      className={joinClassNames(
        classes.typePresets,
        classes.wrapper,
        className,
      )}
    >
      <div className={classes.coin}>
        <FaceIcon className={classes.front} />
        <div className={classes.back}>
          <BackIcon
            className={joinClassNames(classes.icon, {
              [classes.spriteAnimation]: isDefault,
            })}
          />
        </div>

        {Array.from({ length: 40 }).map((_, index) => (
          <span key={`${index + 1}`} className={classes.side} />
        ))}
      </div>
    </div>
  );
};

export default AnimatedCoin;
